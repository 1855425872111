.nav-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding-right: 50px;
  position: sticky;
  top: 0;
  backdrop-filter: blur(10px);
  background-color: rgba(21, 32, 46, 0.75);
  font-size: 1.2rem;
  font-weight: 500;
  z-index: 100;
  height: 60px;
}

.nav-link {
  padding-top: 18px;
  padding-bottom: 18px;
  text-decoration: none;
  color: white;
  transition: all 0.3s;
}

.nav-link:hover {
  color: var(--accent-color-red);
  cursor: pointer;
}

@media (max-width: 600px) {
  .nav-menu {
    padding-right: 10px;
    padding-left: 10px;
    justify-content: center;
    font-size: 1.1rem;
  }
}