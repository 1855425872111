.home {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 2.5rem;
}

.rotatable {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 30px;
  padding: 20px;
  color: white;
}

.rotatable > div {
  animation: focus 2s;
}

.home-my-name {
  color: var(--accent-color-red);
}

.home-buttons {
  display: flex;
  justify-self: center;
  align-items: center;
  gap: 20px;
  backdrop-filter: blur(8px);
}

.home-button {
  position: relative;
  font-family: 'Raleway', sans-serif;
  background-color: rgb(0, 0, 0, 0);
  color: var(--accent-color-red);
  padding: 10px;
  font-size: 1.5rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: all ease-in-out 0.25s;
}

.home-button::after {
  content: '';
  border: 1px solid var(--accent-color-red);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.5s ease-in-out;
  opacity: 1;
}

.home-button::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-top: 1px solid rgb(255, 255, 255, 1);
  border-bottom: 1px solid rgb(255, 255, 255, 1);
  transform: rotateY(90deg);
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.home-button:hover {
  cursor: pointer;
}

.home-button:hover::after {
  transform: rotateX(90deg);
  opacity: 0;
}

.home-button:hover::before {
  transform: rotateY(0deg);
  opacity: 1;
}

.home-button-icon {
  width: 30px;
}

.home-buttons a {
  text-decoration: none;
}

@keyframes focus {
  0% {
    opacity: 0;
    transform: scale(2);
    filter: blur(5px);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    filter: blur(0px);
  }
}

@media (max-width: 600px) {
  .home-buttons {
    flex-direction: column-reverse;
  }
  .home {
    font-size: 2rem;
  }
  .home-button {
    font-size: 1.3rem;
  }
}

.lang-toggle {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 1.5rem;
  z-index: 100;
  animation: langGlide 2500ms ease-in-out;
}

.lang-toggle:hover {
  cursor: pointer;
}

@keyframes langGlide {
  0% {
    left: -100px;
  }

  80% {
    left: -100px;
  }
  90% {
    left: 40px;
  }
  100% {
    left: 20px;
  }
}
