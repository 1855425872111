@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap');

:root {
  --accent-color-red: rgb(211, 72, 72);
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  color: white;
}

.title {
  position: relative;
  margin: 50px 10px;
  display: flex;
  justify-content: center;
}

h2 {
  font-size: 3rem;
  margin: 0;
  text-align: center;
}

h2.shadow {
  position: absolute;
  transform: translate(25%, 45%) scale(1.2);
  opacity: 0.05;
}

.section {
  padding: 20px 20px 0 20px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
}
