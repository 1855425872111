:root {
  --time: 9000ms;
  --way: calc(90vh);
}

.main-background {
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  z-index: -1;
  background: radial-gradient(
    ellipse at bottom,
    rgba(13, 29, 49, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  overflow: hidden;
}

.stars {
  position: fixed;
  width: 100vw;
  height: 100vh;
  transform: rotateZ(135deg);
}

.star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  background: linear-gradient(-45deg, rgb(255, 255, 255), rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px rgb(255, 255, 255));
  animation: tail var(--time) ease-in-out infinite, shooting var(--time) ease-in-out infinite;
  opacity: 0.3;
}
.star::before {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgb(255, 255, 255), rgba(0, 0, 255, 0));
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  animation: shining var(--time) ease-in-out infinite;
}
.star::after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgb(255, 255, 255), rgba(0, 0, 255, 0));
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  animation: shining var(--time) ease-in-out infinite;
  transform: translateX(50%) rotateZ(-45deg);
}
.star1 {
  top: calc(50% - -136px);
  left: calc(50% - 145px);
  animation-delay: 1300ms;
}
.star1::before, .star1::after {
  animation-delay: 1300ms;
}
.star2 {
  top: calc(50% - 79px);
  left: calc(50% - 294px);
  animation-delay: 1900ms;
}
.star2::before, .star2::after {
  animation-delay: 1900ms;
}
.star3 {
  top: calc(50% - 171px);
  left: calc(50% - 81px);
  animation-delay: 2900ms;
}
.star3::before, .star3::after {
  animation-delay: 2900ms;
}
.star4 {
  top: calc(50% - -137px);
  left: calc(50% - 293px);
  animation-delay: 3700ms;
}
.star4::before, .star4::after {
  animation-delay: 3700ms;
}
.star5 {
  top: calc(50% + 300px);
  left: calc(50%);
  animation-delay: 4300ms;
}
.star5::before, .star5::after {
  animation-delay: 4300ms;
}

.star6 {
  top: calc(50% - 250px);
  left: calc(50% - 250);
  animation-delay: 5300ms;
}
.star6::before, .star6::after {
  animation-delay: 5300ms;
}

.star7 {
  top: calc(50% - 200px);
  left: calc(50% - 200px);
  animation-delay: 6700ms;
}
.star7::before, .star7::after {
  animation-delay: 6700ms;
}

.star8 {
  top: calc(50% - 300px);
  left: calc(50% - 300px);
  animation-delay: 7300ms;
}
.star8::before, .star8::after {
  animation-delay: 7300ms;
}

.star9 {
  top: calc(50% - 500px);
  left: calc(50% - 350px);
  animation-delay: 8300ms;
}
.star9::before, .star9::after {
  animation-delay: 8300ms;
}

.star10 {
  top: calc(50%px);
  left: calc(50%px);
  animation-delay: 9700ms;
}
.star10::before, .star10::after {
  animation-delay: 9700ms;
}

@keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}
@keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
}
@keyframes shooting {
  0% {
    transform: translateX(-300px);
  }
  100% {
    transform: translateX(var(--way));
  }
}