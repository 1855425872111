.project-box {
  display: flex;
  gap: 30px;
  padding: 30px 0px;
}

.project-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  z-index: 2;
}

.project-video {
  display: flex;
  flex: 2;
  z-index: 1;
}

.project-box:nth-of-type(2n + 1) {
  flex-direction: row-reverse;
}

video {
  width: 100%;
}

.project-title {
  position: relative;
  margin-bottom: 30px;
}

h3 {
  font-size: 2rem;
  margin: 0;
}

h3.shadow {
  position: absolute;
  transform: translate(25%, 45%) scale(1.2);
  opacity: 0.05;
  top: 0;
}

.project-description-text {
  font-size: 1.2rem;
  line-height: 1.7rem;
}

.project-button {
  position: relative;
  background-color: rgb(0, 0, 0, 0);
  color: var(--accent-color-red);
  font-family: 'Raleway', sans-serif;
  font-size: 1.3rem;
  border: none;
  transition: all ease-in-out 0.25s;
  width: 150px;
  height: 45px;
  backdrop-filter: blur(8px);
}

.project-button::after {
  content: '';
  border: 1px solid var(--accent-color-red);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.5s ease-in-out;
  opacity: 1;
}

.project-button::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-top: 1px solid rgb(255, 255, 255, 1);
  border-bottom: 1px solid rgb(255, 255, 255, 1);
  transform: rotateY(90deg);
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.project-button:hover {
  cursor: pointer;
}

.project-button:hover::after {
  transform: rotateX(90deg);
  opacity: 0;
}

.project-button:hover::before {
  transform: rotateY(0deg);
  opacity: 1;
}

.project-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.project100 {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

@media (max-width: 1000px) {
  .project-box,
  .project-box:nth-of-type(2n + 1) {
    flex-direction: column-reverse;
  }

  .project-info {
    background-color: rgba(21, 32, 46, 0.75);
    backdrop-filter: blur(10px);
    padding: 20px;
    margin: -50px 0px 0px 0px;
    overflow: hidden;
  }

  .project-video {
    width: 100vw;
    transform: translateX(-20px);
  }
}